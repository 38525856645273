import * as React from 'react';
import {
  IResponsiveBoxProps,
  IContainerImperativeActions,
} from '../../../Container.types';
import containerSemanticClassNames from '../../../Container.semanticClassNames';
import ResponsiveBox from '../../../../ResponsiveBox/viewer/ResponsiveBox';

// This container is used in responsive site
const ResponsiveBoxSkin: React.ForwardRefRenderFunction<
  IContainerImperativeActions,
  IResponsiveBoxProps
> = props => (
  <ResponsiveBox {...props} semanticClassNames={containerSemanticClassNames} />
);

export default ResponsiveBoxSkin;
